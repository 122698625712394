
import { dispatchSubmitResetPassword } from '@/store/main/actions';
import { Component, Vue , Prop} from 'vue-property-decorator';

@Component({
  components: {
  },
})
export default class ResetPasswordForm extends Vue {
  public loadingState: any = {
    submit: false,
  };
  public newPassword: string = '';
  public newPasswordType: string = 'password';
  public confirmPassword: string = '';
  public confirmPasswordType: string = 'password';
  public formError: boolean = false;
  public resetError: string = '';
  public resetOK: boolean = false;

  public validateForm() {
    if (!this.newPassword || !this.confirmPassword || this.newPassword !== this.confirmPassword) {
      this.formError = true;
    } else {
      this.formError = false;
    }
    return !this.formError;
  }

  public async handleSubmit() {
    this.resetError = '';
    if (await this.validateForm()) {
      this.loadingState.submit = true;
      const response = await dispatchSubmitResetPassword(
        this.$store, {token: this.$route.params.token, password: this.newPassword},
      );
      if (response == 'OK') {
        // @ts-ignore
        this.$toast.success(this.$t('New password changed successfully'), {
          duration: 2000, position: 'bottom',
        });
        this.$router.push('/login');
      } else {
        this.resetError = response;
      }
      this.loadingState.submit = false;
    }
  }

}
